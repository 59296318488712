import Vue from 'vue';
import { throttle } from 'throttle-debounce';
import { i18n } from 'vue-lang-router';
import App from './App.vue';
import router from './router';
import './main.pcss';

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

// Add throttle to scroll and resize listeners

const dispatchScrollEvent = throttle(200, () => {
  const event = new CustomEvent('windowScrolled');
  document.dispatchEvent(event);
});

window.addEventListener('scroll', () => {
  dispatchScrollEvent();
});
