import preloadedMessages from './de.json';

/* eslint-disable */
export default {
  de: {
    name: 'de',
    messages: preloadedMessages,
  },
  en: {
    name: 'en',
    load: () => {
      return import('./en.json');
    },
  },
  fr: {
    name: 'fr',
    load: () => {
      return import('./fr.json');
    },
  },
  br: {
    name: 'br',
    load: () => {
      return import('./br.json');
    },
  },
  dk: {
    name: 'dk',
    load: () => {
      return import('./dk.json');
    },
  },
  nl: {
    name: 'nl',
    load: () => {
      return import('./nl.json');
    },
  },
  fi: {
    name: 'fi',
    load: () => {
      return import('./fi.json');
    },
  },
  it: {
    name: 'it',
    load: () => {
      return import('./it.json');
    },
  },
  no: {
    name: 'no',
    load: () => {
      return import('./no.json');
    },
  },
  pt: {
    name: 'pt',
    load: () => {
      return import('./pt.json');
    },
  },
  es: {
    name: 'es',
    load: () => {
      return import('./es.json');
    },
  },
  se: {
    name: 'se',
    load: () => {
      return import('./se.json');
    },
  },
  tr: {
    name: 'tr',
    load: () => {
      return import('./tr.json');
    },
  },
  cz: {
    name: 'cz',
    load: () => {
      return import('./cz.json');
    },
  },
};
/* eslint-enable */
