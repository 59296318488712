<template>
  <div v-bind:id="'group-' + counter" class="group text-center mx-auto" v-if="!isLocked">
    <h1 class="h1 mb-5 md:mb-8" v-html="getTitle()"></h1>
    <template v-for="(question, key) in questions">
      <question
        ref="question"
        :data="question"
        :key="question.questionId"
        :isfirst="key === 0"
        :qid="question.questionId"
        :qidlinked="question.linkedQuestion ? question.linkedQuestion.questionId : ''">
      </question>
    </template>
    <button v-if="!hideContinue" class="btn btn-primary mt-10 mb-12 md:mb-0" @click="unlockNextGroup()" v-html="$t('continue')"></button>
  </div>
</template>

<script>
import Question from './Question.vue';

export default {
  name: 'Group',
  props: {
    data: {},
    questions: [],
    counter: {
      type: Number,
    },
  },
  data() {
    return {
      hideContinue: false,
      isLocked: true,
    };
  },
  components: {
    Question,
  },
  mounted() {
    if (this.counter === 0) {
      this.isLocked = false;
    }
  },
  methods: {
    getTitle() {
      return this.data.labels[this.$i18n.locale];
    },
    getSavedAnswer(qId) {
      return this.$parent.getSavedAnswer(qId);
    },
    unlockGroup() {
      if (this.isLocked) {
        this.isLocked = false;
        this.$nextTick(() => {
          setTimeout(() => {
            const element = this.$el;
            const headerOffset = 80;
            const elementPosition = element.offsetTop;
            const offsetPosition = elementPosition - headerOffset;

            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth',
            });
          }, 600);
        });
      }
    },
    unlockNextGroup() {
      this.saveQuestions();
      this.$parent.unlockNextGroup();
      this.hideContinue = true;
    },
    saveQuestions() {
      this.$refs.question.forEach((q) => {
        this.$parent.saveAnswer(q.qid, q.percentage);
        if (q.isLinkedQuestion) {
          this.$parent.saveAnswer(q.isLinkedQuestion, q.percentageOpposite);
        }
      });
    },
    updateQuestionValue(qid, val) {
      this.$parent.updateQuestionValue(qid, val);
      this.$parent.saveAnswer(qid, val);
    },
  },
};
</script>

<style lang="scss">
.group {
  margin-bottom: 40px;
  max-width: 925px;

  @media (min-width: theme('screens.md')) {
    margin-bottom: 120px;
  }
}

.group .btn {
  padding-left: theme('button.horizontalPadding');
  padding-right: theme('button.horizontalPadding');
}
</style>
