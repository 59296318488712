<template>
  <div class="analysis text-center mx-auto">
    <h4 class="h4 uppercase mb-1" v-html="$t('vision-zone-analysis')"></h4>
    <template v-for="(group, key) in groups">
      <group ref="group" :counter="key" :key="group.groupId" :data="group" :questions="getQuestionsByGroupId(group.groupId)"></group>
    </template>

    <!-- form -->
    <div v-if="isFormVisible" v-bind:data-qid="formId" data-first="false" class="form mx-auto text-center px-3">
      <h1 class="h1 mb-8" v-html="$t('form-title')"></h1>
      <img class="question__img-group mx-auto mb-6" :src="resultImage" />
      <p class="p px-2 md:px-4 mb-10" v-html="resultText"></p>
      <div>
        <p v-if="userFromB2B()" class="p mb-12 font-light" v-html="$t('form-text-b2b')"></p>
        <p v-else class="p mb-12 font-light" v-html="$t('form-text')"></p>
      </div>

      <form class="relative" @submit="checkForm" novalidate>
        <!-- email -->
        <div class="w-full mt-6">
          <div class="float-label relative">
            <input id="cform__email" v-model="email" type="email" class="uk-input focus:outline-none" name="email" placeholder=" " autocomplete="email" required
              v-bind:class="{ 'uk-form-danger': isInErrorList('email') }">
            <label for="cform__email" v-html="$t('email')+' *'"></label>
          </div>
        </div>

        <div class="w-full">
          <div class="float-label relative uk-margin-small-bottom">
            <textarea name="comment" v-model="notes" id="cform__comment" class="uk-textarea focus:outline-none" cols="30" rows="6" placeholder=" "></textarea>
            <label for="cform__comment" v-html="$t('notes')"></label>
          </div>
        </div>

        <div class="w-full mt-6 uk-flex uk-flex-center pb-12 md:pb-0">
            <button
              class="btn btn-primary spinner-button-spacing relative"
              data-callback="submitContact"
              :disabled="loading">
              <span v-html="$t('send-result')"></span>
              <span v-if="loading" class="spinner-button-wrapper">
                  <span class="spinner uk-icon"></span>
              </span>
            </button>
        </div>
      </form>
    </div>

    <!-- pagination -->
    <div class="dots md:pr-8 fixed">
      <ul class="flex md:block">
        <template v-for="(group, gKey) in groups">
          <li
            v-for="(question, qKey) in getQuestionsByGroupId(group.groupId)"
            :qid="question.questionId"
            :isfirst="qKey === 0"
            :key="question.questionId"
            v-bind:class=" { 'dot--li': gKey !== 0,  'dot--disabledli': isQuestionLocked(question.questionId) }"
          >
            <button
              @click.prevent="scrollToItem(question.questionId)"
              class="dot dot--question focus:outline-none cursor-pointer dot--spacing"
              v-bind:class=" { 'dot--activegroup': gKey + 1 === activeGroupID,
              'dot--group': qKey === 0, 'dot--active': activeItemID === question.questionId, 'dot--disabled': isQuestionLocked(question.questionId) } ">
            </button>
          </li>
        </template>
        <li
          class="dot--li"
          v-bind:qid="formId"
          v-bind:isfirst="false"
          v-bind:key="formId"
          v-bind:class=" { 'dot--disabledli': !isFormVisible }"
        >
          <button
            @click.prevent="scrollToItem(formId)"
            class="dot dot--question focus:outline-none dot--group"
            v-bind:class=" { 'dot--active': activeItemID === formId, 'dot--disabled': !isFormVisible } ">
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import * as ScrollMagic from 'scrollmagic';
import {
  gsap,
  TweenLite,
  TimelineLite,
  Cubic,
} from 'gsap';
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';
import { CSSRulePlugin } from 'gsap/CSSRulePlugin';
import axios from 'axios';
import debounce from '../scripts/debounce';
import config from '../config';
import Group from '../components/Group.vue';

gsap.registerPlugin(CSSRulePlugin);

ScrollMagicPluginGsap(ScrollMagic, TweenLite, TimelineLite, Cubic);

export default {
  name: 'Analysis',
  components: {
    Group,
  },
  data() {
    return {
      groups: [],
      questions: [],
      counter: 0,
      questionIds: [],
      activeItemID: '',
      activeGroupID: '',
      firstItemId: '',
      scrollPositions: {},
      answers: {},
      errors: [],
      email: null,
      notes: null,
      isFormVisible: false,
      formId: 'form-0',
      loading: false,
      saving: false,
      resizeDebounce: null,
      latestWindowWidth: null,
      resultImage: '',
      resultText: '',
    };
  },
  watch: {
    counter() {
      this.$refs.group.forEach((group, key) => {
        if (key < this.counter) {
          group.unlockGroup();
        }
      });
    },
  },
  created() {
    this.getQuestions();
  },
  mounted() {
    if (!this.$parent.answersId) {
      this.$router.push('/');
    }

    this.setPaginationAndQuestionIds();

    this.$nextTick(() => {
      this.setScrollPositions();

      document.addEventListener('windowScrolled', () => {
        this.checkActiveItem();
      });

      this.setWindowResizeListener();
    });
  },
  methods: {
    userFromB2B() {
      return this.$parent.isFromB2B();
    },
    setWindowResizeListener() {
      this.resizeDebounce = debounce(() => {
        if (this.latestWindowWidth !== window.innerWidth) {
          this.latestWindowWidth = window.innerWidth;
          this.setScrollPositions();
          this.checkActiveItem();
        }
      }, 500);

      this.latestWindowWidth = window.innerWidth;
      window.addEventListener('resize', this.resizeDebounce);
    },
    updateQuestionValue(qid, val) {
      this.answers[qid] = val;
      this.saveAnswer(qid, val);
    },
    saveAnswer(qid, val) {
      const answerVal = {};
      answerVal[qid] = val;
      this.updateResult();

      const answer = {
        email: this.email,
        language: this.$i18n.locale,
        name: '',
        note: this.notes,
        answers: answerVal,
      };
      axios
        .post(`https://${config.server}/visionzoneanalysis/answers/${this.$parent.answersId}`, answer)
        .then((res) => {
          if (res.status === 200) {
            this.updateResult();
          } else {
            console.error('saving answer error');
          }
        })
        .catch((error) => {
          console.log(1, error);
        });
    },
    saveAnswers() {
      this.saving = true;
      this.updateResult();
      const answer = {
        email: this.email,
        language: this.$i18n.locale,
        name: '',
        note: this.notes,
        answers: this.answers,
      };
      axios
        .post(`https://${config.server}/visionzoneanalysis/answers/${this.$parent.answersId}`, answer)
        .then((res) => {
          if (res.status === 200) {
            this.saving = false;
            this.updateResult();
          } else {
            console.error('saving answers error');
            this.saving = false;
          }
        })
        .catch((error) => {
          console.log(1, error);
        });
    },
    updateResult() {
      axios
        .get(`https://${config.server}/visionzoneanalysis/answers/status/${this.$parent.answersId}`)
        .then((res) => {
          if (res.status === 200) {
            this.resultImage = res.data.image ? res.data.image : 'https://media.silhouette.com/sza/intro-sehzonenanalyse@3x.png';
            this.resultText = res.data.text ? res.data.text : '';
          } else {
            console.error('get answers error');
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    checkForm(e) {
      e.preventDefault();

      this.errors = [];
      if (!this.email || !this.validEmail(this.email)) {
        this.errors.push('email');
      }

      if (!this.errors.length) {
        this.loading = true;

        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(config.recaptchaSiteKey, { action: 'submit' }).then((token) => {
            const answer = {
              email: this.email,
              language: this.$i18n.locale,
              name: '',
              note: this.notes,
              answers: this.answers,
              recaptcha: token,
            };
            axios
              .post(`https://${config.server}/visionzoneanalysis/answers/${this.$parent.answersId}`, answer)
              .then((res) => {
                if (res.status === 200) {
                  this.$router.push('/result');
                  this.loading = false;
                } else {
                  console.error('translation error');
                  this.loading = false;
                }
              })
              .catch((error) => {
                console.log(1, error);
              });
          });
        });
      }
    },
    isInErrorList(val) {
      for (let i = 0; i < this.errors.length; i += 1) {
        if (this.errors[i] === val) {
          return true;
        }
      }
      return false;
    },
    validEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    setPaginationAndQuestionIds() {
      // redirect user if access without answers ID
      if (this.groups.length === undefined) {
        this.$router.push('/');
      } else {
      // user has an answersId
        const questionIds = [];
        this.groups.forEach((group, groupKey) => {
          if (groupKey < this.counter) {
            group.unlockGroup();
          }

          const questions = this.getQuestionsByGroupId(group.groupId);

          questions.forEach((question, questionKey) => {
            // Add question
            questionIds.push(`${question.questionId}`);
            if (this.answers === null) {
              this.answers = {};
            }
            if (this.answers[`${question.questionId}`] === undefined) {
              this.answers[`${question.questionId}`] = 0;
            }

            // Add linked question, as it is merged to it's link
            if (question.linkedWith) {
              questionIds.push(`${question.linkedQuestion.questionId}`);
              this.answers[`${question.linkedQuestion.questionId}`] = 0;
            }

            // Set first question as default active item
            if (groupKey === 0 && questionKey === 0) {
              this.firstItemId = `${question.questionId}`;
              this.activeGroupID = groupKey;
            }
          });
        });
        this.questionIds = questionIds;
        this.activeItemID = this.firstItemId;
      }
    },
    isQuestionLocked(id) {
      const ids = Object.keys(this.scrollPositions);
      for (let i = 0; i < ids.length; i += 1) {
        if (id === ids[i]) {
          return false;
        }
      }
      return true;
    },
    setScrollPositions() {
      const elements = this.$el.querySelectorAll('.question');
      const scrollPositions = {};

      elements.forEach((el) => {
        const id = el.getAttribute('data-qid');
        const elTopDistance = el.getBoundingClientRect().top + window.scrollY;
        const scrollPosition = elTopDistance - (window.innerHeight - el.clientHeight) / 2;
        scrollPositions[id] = Math.round(scrollPosition);
      });

      if (this.isFormVisible) {
        const formEl = this.$el.querySelectorAll('.form')[0];
        const formId = formEl.getAttribute('data-qid');
        const formElTopDistance = formEl.getBoundingClientRect().top + window.scrollY;
        const scrollPositionForm = formElTopDistance - (window.innerHeight - formEl.clientHeight) / 2;
        scrollPositions[formId] = Math.round(scrollPositionForm);
      }

      this.scrollPositions = scrollPositions;
    },
    getQuestions() {
      this.groups = this.$parent.groups;
      this.questions = this.$parent.questions;
      this.answers = this.$parent.savedAnswers;
    },
    getQuestionsByGroupId(groupId) {
      const questions = [];

      this.questions.forEach((question) => {
        if (question.group === groupId) {
          questions.push(question);
        }
      });

      return questions;
    },
    getSavedAnswer(questionId) {
      return this.answers[`${questionId}`];
    },
    unlockNextGroup() {
      this.counter += 1;

      this.$nextTick(() => {
        // Wait for animation
        setTimeout(() => {
          this.setScrollPositions();
        }, 600);
      });

      // all groups are visible
      if (this.counter === this.groups.length) {
        this.saveAnswers();
        this.isFormVisible = true;

        this.$nextTick(() => {
          // Wait for animation
          setTimeout(() => {
            this.scrollToItem(this.formId);
          }, 600);
        });
      }
    },
    scrollToItem(id) {
      const element = this.$el.querySelectorAll(`[data-qid="${id}"]`)[0];
      if (element !== undefined) {
        let scrollingPosition = element.getAttribute('data-first') ? element.offsetTop - 160 : element.offsetTop - 80;
        if (id === this.formId) {
          scrollingPosition = element.offsetTop - 80;
        }
        window.scroll({
          top: scrollingPosition,
          behavior: 'smooth',
        });
      }
    },
    checkActiveItem() {
      const ids = Object.keys(this.scrollPositions);
      let activeItemID = '';
      let activeGroupID = '';

      for (let i = 0; i < ids.length; i += 1) {
        const id = ids[i];

        if (
          (activeItemID === '' && window.scrollY + 150 > this.scrollPositions[id])
          || (activeItemID !== ''
          && window.scrollY + 150 > this.scrollPositions[id]
          && this.scrollPositions[activeItemID] < this.scrollPositions[id])
        ) {
          activeItemID = id;
          activeGroupID = parseInt(id.substring(1, id.length), 0);
        }
      }

      this.activeItemID = (activeItemID === '') ? this.firstItemId : activeItemID;
      this.activeGroupID = (activeGroupID === '') ? 0 : activeGroupID;
    },
  },
};
</script>

<style lang="scss">
.analysis {
  padding: 0 82px;

  @media(max-width: theme('screens.xsm')) {
    padding: 0 10px;
  }
}

.form {
  margin-bottom: 120px;
  max-width: theme('contentWidths.medium');

  @media (min-width: theme('screens.sm')) {
    margin-bottom: 200px !important;
  }
}

/* form fields from silhouette.com */
.uk-input {
  display: inline-block;
  overflow: visible;
  padding: 0 0.8em;
  vertical-align: middle;
}

.uk-input,
.uk-textarea {
  background: #fff;
  border: 1px solid theme('colors.grey.300');
  border-radius: 0;
  box-sizing: border-box;
  color: theme('colors.black');
  font: inherit;
  margin: 0;
  max-width: 100%;
  padding: 0 0.8em;
  transition: .2s ease-in-out;
  transition-property: color, background-color, border;
  -webkit-appearance: none;
  width: 100%;
}

.float-label {
  input {
    font-size: theme('fontSize.xs');
    height: 20px * 2.5;
    padding-bottom: 2px;
    padding-top: 25px;

    @media (min-width: theme('screens.md')) {
      font-size: theme('fontSize.sm');
    }
  }

  textarea {
    border: 0;
    border-bottom: 1px solid theme('colors.grey.300');
    height: 8em;
    line-height: 26px;
    margin-top: 25px;
    padding-left: 0;
    padding-top: 0;
    transition: height 0.3s;

    /* stylelint-disable-next-line selector-no-vendor-prefix */
    &::-webkit-input-placeholder {
      opacity: 1;
      transition: all 0.3s;
    }

    /* stylelint-disable-next-line selector-no-vendor-prefix */
    &:placeholder-shown:not(:focus)::-webkit-input-placeholder {
      opacity: 0;
    }

    &:placeholder-shown:not(:focus) {
      height: 2em;
    }

    &:placeholder-shown:not(:focus) + label {
      color: theme('colors.black');
      font-size: 16px;
      top: 29px;
    }

    &:focus {
      border-bottom: 1px solid theme('colors.black');
    }
  }

  input {
    border: 0;
    border-bottom: 1px solid theme('colors.grey.300');
    padding-left: 0;

    /* stylelint-disable-next-line selector-no-vendor-prefix */
    &::-webkit-input-placeholder {
      opacity: 1;
      transition: all 0.3s;
    }

    /* stylelint-disable-next-line selector-no-vendor-prefix */
    &:placeholder-shown:not(:focus)::-webkit-input-placeholder {
        opacity: 0;
    }

    &:placeholder-shown:not(:focus) + label {
      color: theme('colors.black');
      font-size: 16px;
      top: 22px;
    }

    &:focus {
      border-bottom: 1px solid theme('colors.black');
    }
  }

  label {
    color: theme('colors.label');
    font-size: 13px;
    font-weight: 300;
    left: 0;
    position: absolute;
    top: 0;
    transition: all 0.3s;

    &:hover {
      cursor: text;
    }
  }

  .uk-form-danger {
    border-color: theme('colors.red') !important;

    + label {
      color: theme('colors.red') !important;
    }
  }
}

/* dot pagination right on desktop, bottom on mobile */
.dots {
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  @media(max-width: theme('screens.xsm')) {
    background-color: #fff;
    bottom: 0;
    box-shadow: theme('boxShadows.container');
    display: flex;
    justify-content: center;
    top: auto;
    transform: none;
    width: 100vw;
  }
}

.dot {
  display: block;
  height: 18px;
  position: relative;
  width: 18px;

  @media(max-width: theme('screens.xsm')) {
    padding: 30px;

    &:before,
    &:after {
      right: 50% !important;
      top: 50% !important;
      transform: translate(50%, -50%);
    }
  }

  &:before {
    cursor: pointer;
    transition: background-color 300ms ease, border-color 300ms ease;
  }

  &:after {
    cursor: pointer;
    transition: border-color 300ms ease;
  }

  &.dot--disabled {
    cursor: auto;
  }
}

.dot--li {
  @media(max-width: theme('screens.xsm')) {
    position: relative;

    &:before {
      background-color: theme('colors.black');
      content: '';
      display: block;
      height: 1px;
      position: absolute;
      left: -15px;
      top: 50%;
      width: 30px;
    }

    &.dot--disabledli:before {
      background-color: theme('colors.disabled') !important;
    }
  }
}

.dot--activegroup.dot--group {
  @media(max-width: theme('screens.xsm')) {
    &:before {
      background-color: theme('colors.primary') !important;
      border-color: theme('colors.primary') !important;
      height: 10px;
      width: 10px;
    }

    &:after {
      border: 1px solid theme('colors.primary');
      border-radius: 9999px;
      content: '';
      display: block;
      height: 18px;
      position: absolute;
      right: 0;
      top: 0;
      width: 18px;
    }
  }
}

.dot--active {
  &:after {
    border: 1px solid theme('colors.primary');
    border-radius: 9999px;
    content: '';
    display: block;
    height: 18px;
    position: absolute;
    right: 0;
    top: 0;
    width: 18px;
  }

  ~ .dot--group:before {
    background-color: theme('colors.primary') !important;
  }
}

.dot--group {
  &:before {
    background-color: theme('colors.black');
    border-radius: 9999px;
    content: '';
    display: block;
    height: 10px;
    position: absolute;
    right: 4px;
    top: 4px;
    width: 10px;
  }

  &.dot--active:before {
    background-color: theme('colors.primary') !important;
  }

  &.dot--disabled:before {
    background-color: theme('colors.disabled') !important;
    cursor: auto;
  }

  @media(max-width: theme('screens.xsm')) {
    display: block !important;
  }
}

.dot--question {
  &:before {
    border: 1px solid theme('colors.black');
    border-radius: 9999px;
    content: '';
    display: block;
    height: 6px;
    position: absolute;
    right: 6px;
    top: 6px;
    width: 6px;
  }

  &.dot--active:before {
    border-color: theme('colors.primary') !important;
    height: 10px;
    left: 4px;
    top: 4px;
    width: 10px;

    @media(max-width: theme('screens.xsm')) {
      left: 20px;
    }
  }

  &.dot--disabled:before {
    border-color: theme('colors.disabled') !important;
    cursor: auto;
  }

  @media(max-width: theme('screens.xsm')) {
    display: none;
  }

  &.dot--spacing {
    @media (min-width: theme('screens.md')) {
      margin-bottom: 6px;
    }
  }
}

/* form button spinner from silhouette.com */
.uk-icon {
  background-color: transparent;
  border: none;
  border-radius: 0;
  color: inherit;
  display: inline-block;
  fill: currentcolor;
  font: inherit;
  line-height: 0;
  margin: 0;
  overflow: visible;
  padding: 0;
  text-transform: none;
}

.spinner {
    animation: rotation 0.8s ease infinite;
    border: 3px solid theme('colors.disabled');
    border-radius: 50%;
    border-top-color: theme('colors.grey.500');
    box-sizing: border-box;
    height: 2rem;
    width: 2rem;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.spinner-button-spacing {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    position: relative;
}

.spinner-button-wrapper {
    height: 1.5rem;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translate(30%, -50%);
    width: 1.5rem;

    .spinner {
        height: 1.5rem;
        width: 1.5rem;
    }
}
</style>
