<template>
  <div class="result text-center mx-auto px-5 mb-12">
    <img class="result__image mx-auto mb-10" src="../assets/check@2x.png" />
    <h4 class="h4 uppercase mb-1" v-html="$t('vision-zone-analysis')"></h4>
    <h1 class="h1 mb-8" v-html="$t('result-title')"></h1>
    <div>
      <p v-if="userFromB2B()" class="p mb-12 font-light" v-html="$t('result-text-b2b')"></p>
      <p v-else class="p mb-12 font-light" v-html="$t('result-text')"></p>
      <localized-link to="/" class="btn btn-primary" v-html="$t('close')"></localized-link>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Result',
  components: {
  },
  methods: {
    userFromB2B() {
      return this.$parent.isFromB2B();
    },
  },
};
</script>

<style lang="scss">
.result {
  max-width: theme('contentWidths.medium');

  h1 {
    line-height: theme('lineHeight.tight');
  }

  .btn {
    padding-left: theme('button.horizontalPadding');
    padding-right: theme('button.horizontalPadding');
  }
}

.result__image {
  max-width: 130px;
}
</style>
