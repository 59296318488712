<template>
  <div class="container mt-6 md:mt-10 font-sans navigation--expanded">
    <!-- Navigation -->
    <nav class="navigation fixed w-full top-0 z-50 text-center">
        <div class="navigation-inner relative">
          <localized-link to="/" class="inline-block navigation-logo">
            <img class="silhouette-logo" alt="Silhouette International Logo" src="./assets/silhouette-int.svg">
          </localized-link>
        </div>
    </nav>
    <div class="navigation-spacer"></div>

    <!-- Content -->
    <router-view />
  </div>
</template>

<script>
import axios from 'axios';
import config from './config';

export default {
  name: 'App',
  data() {
    return {
      answersId: null,
      lastScrollPosition: 0,
      collapseBegin: 127, // do not collapse navigation until user scrolled a bit
      labels: false,
      groups: {},
      questions: {},
      savedAnswers: {},
      fromB2B: false,
    };
  },
  created() {
    this.getLocoData();
  },
  mounted() {
    this.lastScrollPosition = window.scrollY;
    document.addEventListener('windowScrolled', this.pageScrolling);
  },

  methods: {
    setAnswersId(id) {
      this.answersId = id;
      this.getSavedAnswers();
    },
    setFromB2B(val) {
      this.fromB2B = val;
    },
    getSavedAnswers() {
      axios
        .get(`https://${config.server}/visionzoneanalysis/answers/status/${this.answersId}`)
        .then((res) => {
          if (res.status === 200) {
            this.savedAnswers = res.data.answers;
          } else {
            console.error('get answers error');
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getLocoData() {
      axios
        .get(`https://${config.server}/visionzoneanalysis/questions`)
        .then((res) => {
          if (res.status === 200) {
            this.labels = res.data.labels;
            this.groups = res.data.groups;
            this.setLinkedQuestions(res.data.questions);
          } else {
            console.error('get questions error');
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setLinkedQuestions(questions) {
      const questionFusion = [];
      let linkedQuestion = null;
      let linkedId = null;

      questions.forEach((question) => {
        if (question.linkedWith) {
          if (linkedId) {
            linkedId = null;
            linkedQuestion.linkedQuestion = question;
            questionFusion.push(linkedQuestion);
          } else {
            linkedId = question.linkedWith;
            linkedQuestion = question;
          }
        } else {
          questionFusion.push(question);
        }
      });

      this.questions = questionFusion;
    },
    getAnswersId() {
      return this.answersId;
    },
    isFromB2B() {
      return this.fromB2B;
    },
    /**
      * Generate Answers ID if not set via B2B Shop
    */
    generateAnswersId() {
      axios
        .post(`https://${config.server}/visionzoneanalysis/answers/generate-id`)
        .then((res) => {
          if (res.status === 200) {
            this.$nextTick(() => {
              this.answersId = res.data.id;
            });
          } else {
            console.error('answers ID generation error');
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    /**
      * Navigation methods to expand / collapse / identify page scrolling & direction
    */
    expandNavigation() {
      this.$el.classList.add('navigation--expanded');
    },
    collapseNavigation() {
      this.$el.classList.remove('navigation--expanded');
    },
    pageScrolling() {
      const currentScrollPosition = window.scrollY;
      const currentScrollDirection = this.getCurrentScrollDirection(currentScrollPosition);

      if (currentScrollPosition > this.collapseBegin) {
        if (currentScrollDirection === 'down') {
          this.collapseNavigation();
        } else if (currentScrollDirection === 'up') {
          this.expandNavigation();
        }
      }
      this.lastScrollPosition = currentScrollPosition;
    },
    getCurrentScrollDirection(currentScrollPosition) {
      if (currentScrollPosition > this.lastScrollPosition) {
        return 'down';
      }

      if (currentScrollPosition < this.lastScrollPosition) {
        return 'up';
      }

      return 'none';
    },
  },
};
</script>

<style lang="scss">
html {
  font-size: theme('fontSize.sm');
  -webkit-font-smoothing: antialiased;
}

.h1 {
  font-size: theme('fontSize.lg');
  @media (min-width: theme('screens.md')) {
    font-size: theme('fontSize.2xl');
  }
}

.h2 {
  font-size: theme('fontSize.base');
  @media (min-width: theme('screens.md')) {
    font-size: theme('fontSize.xl');
  }
}

.h4 {
  font-size: theme('fontSize.xs');
  @media (min-width: theme('screens.md')) {
    font-size: theme('fontSize.sm');
  }
}

.p {
  font-size: theme('fontSize.xs');
  @media (min-width: theme('screens.md')) {
    font-size: theme('fontSize.sm');
  }
}

.navigation-inner {
  @apply block overflow-hidden;
  background: hsla(0, 0%, 100%, 0.95);
  height: 55px;
  transition: height 150ms ease-in-out;
}

.navigation-logo {
  @apply transform scale-75;
  transition: transform 600ms ease-in-out;
}

@screen lg {
  .navigation-inner {
    @apply flex flex-col items-center justify-center p-0 h-auto overflow-visible;
    transition: padding 600ms ease-in-out;
  }

  .navigation--expanded {
    .navigation-inner {
      @apply py-4;
    }

    .navigation-logo {
      @apply transform scale-100;
    }
  }
}

.navigation-spacer {
  height: 55px;

  @screen lg {
    height: 87px;
  }
}

.btn {
  @apply appearance-none uppercase font-bold text-xs border leading-none px-10 py-3 inline-block transition-colors duration-100 ease-in-out tracking-wider;
  max-width: 280px;

  &:disabled,
  &:disabled:hover,
  &:disabled:focus {
    @apply cursor-not-allowed;
  }

  &:focus {
    @apply outline-none;
  }
}

.btn-primary {
  @apply bg-black border-black text-white;

  &:hover,
  &:focus {
    @apply bg-grey-500 border-grey-500;
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:focus {
    @apply bg-grey-300 border-grey-300;
  }
}
</style>
