<template>
  <div
    class="question text-center mt-5 md:mt-10 pt-12 pb-12"
    v-bind:class="{ 'question--linked': isLinkedQuestion }"
    v-bind:data-qid="qid"
    v-bind:data-first="isfirst"
  >
    <!-- :start - Linked Question Images -->
    <div v-if="isLinkedQuestion" class="flex question__img-linked px-6 lg:px-0 mx-auto">
      <div class="justify-start content-start flex flex-wrap flex-1 lg:pr-4">
        <div class="question__img-group--left relative mb-10 lg:mx-auto">
          <img class="question__img" :src="data.asset">
          <img v-bind:style="{ opacity: opacity }" class="question__bg absolute top-0 right-0" :src="data.assetBackground">
          <h2 class="h2 mt-5" v-html="getSubTitle()"></h2>
          <div class="flex-1 flex flex-wrap justify-center lg:hidden text-primary font-bold">
            <span class="p" v-html="percentage + '%'"></span>
          </div>
        </div>
      </div>
      <div class="justify-end content-end flex flex-wrap flex-1 lg:pl-4">
        <div class="question__img-group--right relative mb-10 lg:mx-auto">
          <img class="question__img" :src="data.linkedQuestion.asset">
          <img v-bind:style="{ opacity: opacityOpposite }" class="question__bg absolute top-0 right-0" :src="data.linkedQuestion.assetBackground">
          <h2 class="h2 mt-5" v-html="getLinkedSubTitle()"></h2>
          <div class="flex-1 flex flex-wrap justify-center lg:hidden text-primary font-bold">
            <span class="p" v-html="percentageOpposite + '%'"></span>
          </div>
        </div>
      </div>
    </div>
    <!-- Single Question Image -->
    <div v-else class="question__img-group relative mx-auto mb-10 px-6 md:mt-2">
      <img class="question__img" :src="data.asset">
      <img v-bind:style="{ opacity: opacity }" class="question__bg absolute top-0 right-0 px-6" :src="data.assetBackground">
    </div>
    <!-- :end -->
    <h2 class="h2 px-2 md:px-4" v-html="getTitle()"></h2>
    <p class="p px-2 md:px-4" v-if="!isLinkedQuestion" v-html="'(' + getSubTitle() + ')'"></p>
    <div class="question__range-selection flex justify-center md:mb-6">
      <div class="flex-1 flex flex-wrap content-end justify-end lg:flex hidden" v-bind:class="{ 'text-primary': isLinkedQuestion }">
        <span class="font-bold" v-if="isLinkedQuestion" v-html="percentage + '%'"></span>
        <span v-else v-html="$t('not-important')"></span>
      </div>
      <div class="question__range px-6 w-full">
        <input class="z-10 range bg-transparent h-px w-full rounded-none" type="range" max="100" v-model="percentage" step="10" min="0">
        <div class="bg-grey-320 h-px w-full rounded-none relative question__range-selected">
          <span
            v-if="!isLinkedQuestion"
            class="question__range-value absolute text-primary pl-2 font-bold"
            v-bind:style="{ left: percentage + '%' }"
            v-html="percentage + '%'"></span>
          <span
            class="bg-black h-px absolute left-0 top-0 rounded-none font-bold"
            v-bind:style="{ width: percentage + '%' }"
          ></span>
        </div>
        <div class="question__range-markers flex justify-between text-xs text-gray-600">
          <span class="w-px bg-grey-320 h-2 text-left" v-bind:class="{ 'bg-black': percentage >= 0 }"></span>
          <span class="w-px bg-grey-320 h-2 text-center" v-bind:class="{ 'bg-black': percentage >= 10 }"></span>
          <span class="w-px bg-grey-320 h-2 text-center" v-bind:class="{ 'bg-black': percentage >= 20 }"></span>
          <span class="w-px bg-grey-320 h-2 text-center" v-bind:class="{ 'bg-black': percentage >= 30 }"></span>
          <span class="w-px bg-grey-320 h-2 text-center" v-bind:class="{ 'bg-black': percentage >= 40 }"></span>
          <span class="w-px bg-grey-320 h-2 text-center" v-bind:class="{ 'bg-black': percentage >= 50 }"></span>
          <span class="w-px bg-grey-320 h-2 text-center" v-bind:class="{ 'bg-black': percentage >= 60 }"></span>
          <span class="w-px bg-grey-320 h-2 text-center" v-bind:class="{ 'bg-black': percentage >= 70 }"></span>
          <span class="w-px bg-grey-320 h-2 text-center" v-bind:class="{ 'bg-black': percentage >= 80 }"></span>
          <span class="w-px bg-grey-320 h-2 text-center" v-bind:class="{ 'bg-black': percentage >= 90 }"></span>
          <span class="w-px bg-grey-320 h-2 text-right" v-bind:class="{ 'bg-black': percentage >= 100 }"></span>
        </div>
      </div>
      <div class="flex-1 flex flex-wrap content-end justify-start lg:flex hidden" v-bind:class="{ 'text-primary': isLinkedQuestion }">
        <span class="font-bold" v-if="isLinkedQuestion" v-html="percentageOpposite + '%'"></span>
        <span v-else v-html="$t('very-important')"></span>
      </div>
    </div>
    <div v-if="!isLinkedQuestion" class="p flex px-6 w-full lg:hidden mt-4 question__legend mx-auto">
      <div class="flex-1 flex flex-wrap content-end justify-start">
        <span v-html="$t('not-important')"></span>
      </div>
      <div class="flex-1 flex flex-wrap content-end justify-end">
        <span v-html="$t('very-important')"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Question',
  props: {
    data: {},
    isfirst: {
      type: Boolean,
    },
    qid: {
      type: String,
    },
    qidlinked: {
      type: String,
    },
  },
  data() {
    return {
      max: 100,
      percentage: this.getSavedPercentage(),
    };
  },
  watch: {
    percentage() {
      if (this.isLinkedQuestion) {
        this.$parent.updateQuestionValue(this.qid, this.percentage);
        this.$parent.updateQuestionValue(this.qidlinked, this.percentageOpposite);
      } else {
        this.$parent.updateQuestionValue(this.qid, this.percentage);
      }
    },
  },
  computed: {
    opacity() {
      return (this.percentage / 100) < 0.1 ? 0.1 : (this.percentage / 100);
    },
    percentageOpposite() {
      return (100 - this.percentage);
    },
    opacityOpposite() {
      return (1 - this.opacity) < 0.1 ? 0.1 : (1 - this.opacity);
    },
    isLinkedQuestion() {
      return this.data.linkedWith;
    },
  },
  created() {
  },
  methods: {
    getSavedPercentage() {
      return this.$parent.getSavedAnswer(this.qid);
    },
    getTitle() {
      return this.data.headlineLabels[this.$i18n.locale];
    },
    getSubTitle() {
      return this.data.labels[this.$i18n.locale];
    },
    getLinkedSubTitle() {
      return this.data.linkedQuestion.labels[this.$i18n.locale];
    },
  },
};
</script>

<style lang="scss">
.question {
  box-shadow: theme('boxShadows.container');
}

.question--linked {
  padding-bottom: 60px;
  padding-top: 80px;
}

.question__img-linked {
  max-width: theme('questions.width');

  @media (min-width: theme('screens.md')) {
    max-width: 600px;
  }

  @media (min-width: theme('screens.lg')) {
    max-width: none;
  }
}

.question__img-group {
  max-width: 441px;
}

.question__range {
  margin-top: 40px;
  max-width: theme('questions.width');

  .question--linked & {
    margin-top: 0;
    transform: translateY(-50%);
  }

  @media (min-width: theme('screens.md')) {
    margin-top: 45px;
  }
}

.question__legend {
  max-width: theme('questions.width');
}

.question__range-value {
  transform: translateX(-50%) translateY(-50px);
}

.question__range-selector {
  transform: scale(5);
}

.question--linked .question__range-selection .text-primary {
  transform: translateY(-45%);
}

.question__range-markers {
  z-index: -1;

  span {
    transform: translateY(-50%);
    z-index: -1;
  }
}

.question__range-selected {
  z-index: -1;
}

.question__img-group--left {
  max-width: theme('questions.linkedImageWidth');

  @media(max-width: theme('screens.xsm')) {
    max-width: theme('questions.linkedImageWidthSm');
  }
}

.question__img-group--right {
  max-width: theme('questions.linkedImageWidth');

  @media(max-width: theme('screens.xsm')) {
    max-width: theme('questions.linkedImageWidthSm');
  }
}

.question__bg {
  z-index: -1;
}

.bg-grey-320.bg-black {
  background-color: black !important;
}

/* RANGE slider custom styling */
.range {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  transform: translateY(7px);
  width: 100%;
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: theme('colors.primary');
  border-color: theme('colors.primary');
  border-radius: 9999px;
  cursor: pointer;
  height: 30px;
  transform: scale(1);
  transition: transform 300ms ease;
  width: 30px;

  &:focus,
  &:active {
    transform: scale(1.1);
  }

  @media (min-width: theme('screens.md')) {
    box-shadow: none;
    transform: scale(1.2);
    transition: transform 300ms ease, box-shadow 300ms ease;

    &:hover {
      box-shadow: 0 2px 3px 1px rgba(118, 118, 118, 0.15);
    }

    &:focus,
    &:active {
      transform: scale(1.35);
    }
  }

}

.range::-moz-range-thumb {
  background: theme('colors.primary');
  border-radius: 9999px;
  cursor: pointer;
  height: 30px;
  width: 30px;

  @media (min-width: theme('screens.md')) {
    height: 38px;
    transform: scale(1) translateX(-50%);
    transition: transform 300ms ease;
    width: 38px;

    &:hover {
      transform: scale(1.1) translateX(-50%);
    }
  }
}
</style>
