import Vue from 'vue';
import LangRouter from 'vue-lang-router';
import translations from '../lang/translations';
// import VueRouter from 'vue-router';

import Introduction from '../views/Introduction.vue';
import Analysis from '../views/Analysis.vue';
import Result from '../views/Result.vue';

// Vue.use(VueRouter);

Vue.use(LangRouter, {
  defaultLanguage: 'de',
  translations,
});

const routes = [
  {
    path: '/',
    name: 'Introduction',
    component: Introduction,
  },
  {
    path: '/answersId/:answersId',
    name: 'Introduction-AnswersId',
    component: Introduction,
  },
  {
    path: '/analysis',
    name: 'Analysis',
    component: Analysis,
  },
  {
    path: '/analysis/:answersId',
    name: 'Analysis-AnswersId',
    component: Analysis,
  },
  {
    path: '/result',
    name: 'Result',
    component: Result,
  },
];

const router = new LangRouter({
  routes,
  mode: 'history',
});

export default router;
