<template>
  <div class="introduction text-center mx-auto px-5 mb-12">
    <img class="introduction__image mx-auto mb-6 md:mb-10" src="https://media.silhouette.com/sza/intro-sehzonenanalyse@3x.png" />
    <h4 class="h4 uppercase mb-1" v-html="$t('vision-zone-analysis')"></h4>
    <h1 class="h1 mb-6 md:mb-8" v-html="$t('introduction-title')"></h1>
    <div>
      <p class="p mb-6 md:mb-12 font-light" v-html="$t('introduction-text')"></p>
      <localized-link :to="btnLink" class="btn btn-primary" v-html="$t('start')"></localized-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Introduction',
  components: {
  },
  data() {
    return {
      answersId: '',
      btnLink: '/analysis',
    };
  },
  mounted() {
    if (this.$route.params.answersId) {
      this.$parent.setAnswersId(this.$route.params.answersId);
      this.$parent.setFromB2B(true);
      this.answersId = this.$route.params.answersId;
      this.btnLink = `${this.btnLink}/${this.answersId}`;
    } else {
      this.$parent.generateAnswersId();
      this.$parent.setFromB2B(false);
    }
  },
  methods: {
  },
};
</script>

<style lang="scss">
.introduction {
  max-width: theme('screens.md');

  h1 {
    line-height: theme('lineHeight.tight');
  }

  .btn {
    padding-left: theme('button.horizontalPadding');
    padding-right: theme('button.horizontalPadding');
  }
}

.introduction__image {
  max-width: 138px;

  @media(min-width: theme('screens.md')) {
    max-width: 188px;
  }
}
</style>
